import { ImetricOptionsVM } from '@/shared/model/metricOptionsVM.model';
import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const DeviceBrand = () => import('@/entities/device-brand/device-brand.vue');
// prettier-ignore
const DeviceBrandUpdate = () => import('@/entities/device-brand/device-brand-update.vue');
// prettier-ignore
const DeviceBrandDetails = () => import('@/entities/device-brand/device-brand-details.vue');
// prettier-ignore
const DeviceModel = () => import('@/entities/device-model/device-model.vue');
// prettier-ignore
const DeviceModelUpdate = () => import('@/entities/device-model/device-model-update.vue');
// prettier-ignore
const DeviceModelDetails = () => import('@/entities/device-model/device-model-details.vue');
// prettier-ignore
const DeviceFirmware = () => import('@/entities/device-firmware/device-firmware.vue');
// prettier-ignore
const DeviceFirmwareUpdate = () => import('@/entities/device-firmware/device-firmware-update.vue');
// prettier-ignore
const DeviceFirmwareDetails = () => import('@/entities/device-firmware/device-firmware-details.vue');
// prettier-ignore
const DeviceImage = () => import('@/entities/device-image/device-image.vue');
// prettier-ignore
const DeviceImageUpdate = () => import('@/entities/device-image/device-image-update.vue');
// prettier-ignore
const DeviceImageDetails = () => import('@/entities/device-image/device-image-details.vue');
// prettier-ignore
const DeviceTags = () => import('@/entities/device-tags/device-tags.vue');
// prettier-ignore
const DeviceTagsUpdate = () => import('@/entities/device-tags/device-tags-update.vue');
// prettier-ignore
const DeviceTagsDetails = () => import('@/entities/device-tags/device-tags-details.vue');
// prettier-ignore
const Device = () => import('@/entities/device/device.vue');
// prettier-ignore
const DeviceUpdate = () => import('@/entities/device/device-update/device-update.vue');
// prettier-ignore
const DeviceDetails = () => import('@/entities/device/device-details.vue');
const DeviceDashboardView = () => import('@/entities/device/device-dashboard.vue');
// const DeviceNavigationMenu = () => import('@/entities/device/device-navigation-menu.vue');
const H8AlertTable = () => import('@/entities/device/device-h-8-alert.vue');
const DeviceAlertSNMPInterface = () => import('@/entities/device-h-8-alert-conf/device-h8alert-snmp-interface.vue');
const DeviceNetworkInterface = () => import('@/entities/device/device-network-interface.vue');
const DeviceOpticalPortPowerInterface = () => import('@/entities/device/device-optical-port-power-interface.vue');
const DeviceDetailWlan = () => import('@/entities/device/device-tr069/device-details-wlan.vue');
const DeviceDetailHost = () => import('@/entities/device/device-tr069/device-host.vue');
const DeviceDetailLan = () => import('@/entities/device/device-tr069/device-details-lan.vue');
const DeviceDetailWan = () => import('@/entities/device/device-tr069/device-details-wan.vue');
const DeviceDetailDHCP = () => import('@/entities/device/device-tr069/device-details-dhcp.vue');
const DevicePingDiagnostic = () => import('@/entities/device/device-tr069/device-ping-diagnostic.vue');
const DeviceTraceDiagnostic = () => import('@/entities/device/device-tr069/device-trace-route-diagnostic.vue');
const NestedRoute = () => import('@/entities/device/nested-route.vue');
const DeviceNotification = () => import('@/entities/device/device-notification/device-notification.vue');
const DeviceNotificationRecipients = () => import('@/entities/device/device-notification/device-notification-recipients.vue');
const InterfaceAlertConfView = () => import('@/entities/device/device-alert-conf-threshold/device-interface-alert-conf-view.vue');
const OpticalPowerPortThresholdView = () => import('@/entities/device/device-alert-conf-threshold/device-optical-port-power-threshold.vue');
const DeviceAction = () => import('@/entities/device/device-tr069/device-action.vue');
const DeviceSpeedTest = () => import('@/entities/device/device-tr069/device-speed-test/device-speed-test.vue');

// prettier-ignore
const DeviceConfTR069 = () => import('@/entities/device-conf-tr-069/device-conf-tr-069.vue');
// prettier-ignore
const DeviceConfTR069Update = () => import('@/entities/device-conf-tr-069/device-conf-tr-069-update.vue');
// prettier-ignore
const DeviceConfTR069Details = () => import('@/entities/device-conf-tr-069/device-conf-tr-069-details.vue');
// prettier-ignore
const DevideConfSnmp = () => import('@/entities/devide-conf-snmp/devide-conf-snmp.vue');
// prettier-ignore
const DevideConfSnmpUpdate = () => import('@/entities/devide-conf-snmp/devide-conf-snmp-update.vue');
// prettier-ignore
const DevideConfSnmpDetails = () => import('@/entities/devide-conf-snmp/devide-conf-snmp-details.vue');
// prettier-ignore
const Monitor = () => import('@/entities/monitor/monitor.vue');
// prettier-ignore
const MonitorUpdate = () => import('@/entities/monitor/monitor-update.vue');
// prettier-ignore
const MonitorDetails = () => import('@/entities/monitor/monitor-details.vue');
// prettier-ignore
const H8Alert = () => import('@/entities/h-8-alert/h-8-alert.vue');
// prettier-ignore
const H8AlertUpdate = () => import('@/entities/h-8-alert/h-8-alert-update.vue');
// prettier-ignore
const H8AlertDetails = () => import('@/entities/h-8-alert/h-8-alert-details.vue');
// prettier-ignore
const Role = () => import('@/entities/role/role.vue');
// prettier-ignore
const RoleUpdate = () => import('@/entities/role/role-update.vue');
// prettier-ignore
const RoleDetails = () => import('@/entities/role/role-details.vue');
// prettier-ignore
const Ability = () => import('@/entities/ability/ability.vue');
// prettier-ignore
const AbilityUpdate = () => import('@/entities/ability/ability-update.vue');
// prettier-ignore
const AbilityDetails = () => import('@/entities/ability/ability-details.vue');
// prettier-ignore
const EmailRecipientList = () => import('@/entities/email-recipient-list/email-recipient-list.vue');
// prettier-ignore
const EmailRecipientListUpdate = () => import('@/entities/email-recipient-list/email-recipient-list-update.vue');
// prettier-ignore
const EmailRecipientListDetails = () => import('@/entities/email-recipient-list/email-recipient-list-details.vue');
// prettier-ignore
const SMSRecipientList = () => import('@/entities/sms-recipient-list/sms-recipient-list.vue');
// prettier-ignore
const SMSRecipientListUpdate = () => import('@/entities/sms-recipient-list/sms-recipient-list-update.vue');
// prettier-ignore
const SMSRecipientListDetails = () => import('@/entities/sms-recipient-list/sms-recipient-list-details.vue');
// prettier-ignore
const DeviceNotificationRecipient = () => import('@/entities/device-notification-recipient/device-notification-recipient.vue');
// prettier-ignore
const DeviceNotificationRecipientUpdate = () => import('@/entities/device-notification-recipient/device-notification-recipient-update.vue');
// prettier-ignore
const DeviceNotificationRecipientDetails = () => import('@/entities/device-notification-recipient/device-notification-recipient-details.vue');
// prettier-ignore
const DeviceGroup = () => import('@/entities/device-group/device-group.vue');
// prettier-ignore
const DeviceGroupUpdate = () => import('@/entities/device-group/device-group-update.vue');
// prettier-ignore
const DeviceGroupDetails = () => import('@/entities/device-group/device-group-details.vue');
const Job = () => import('@/entities/job/job.vue');
// prettier-ignore
const JobUpdate = () => import('@/entities/job/job-update.vue');
// prettier-ignore
const JobDetails = () => import('@/entities/job/job-details.vue');
const AlertProfile = () => import('@/entities/alert-profile/alert-profile.vue');
// prettier-ignore
const AlertProfileUpdate = () => import('@/entities/alert-profile/alert-profile-update.vue');
// prettier-ignore
const AlertProfileDetails = () => import('@/entities/alert-profile/alert-profile-details.vue');
// prettier-ignore
const DeviceFilter = () => import('@/entities/device-filter/device-filter.vue');
// prettier-ignore
const DeviceFilterUpdate = () => import('@/entities/device-filter/device-filter-update.vue');
// prettier-ignore
const DeviceFilterDetails = () => import('@/entities/device-filter/device-filter-details.vue');
// prettier-ignore
const FirmwareContent = () => import('@/entities/firmware-content/firmware-content.vue');
// prettier-ignore
const FirmwareContentUpdate = () => import('@/entities/firmware-content/firmware-content-update.vue');
// prettier-ignore
const FirmwareContentDetails = () => import('@/entities/firmware-content/firmware-content-details.vue');
// prettier-ignore
const BulkJob = () => import('@/entities/bulk-job/bulk-job.vue');
// prettier-ignore
const BulkJobUpdate = () => import('@/entities/bulk-job/bulk-job-update.vue');
// prettier-ignore
const BulkJobDetails = () => import('@/entities/bulk-job/bulk-job-details.vue');
// prettier-ignore
const Organization = () => import('@/entities/organization/organization.vue');
// prettier-ignore
const OrganizationUpdate = () => import('@/entities/organization/organization-update.vue');
// prettier-ignore
const OrganizationDetails = () => import('@/entities/organization/organization-details.vue');
// prettier-ignore
const ResourceConfiguration = () => import('@/entities/resource-configuration/resource-configuration.vue');
// prettier-ignore
const ResourceConfigurationUpdate = () => import('@/entities/resource-configuration/resource-configuration-update.vue');
// prettier-ignore
const ResourceConfigurationDetails = () => import('@/entities/resource-configuration/resource-configuration-details.vue');
const RecurringBulkJob = () => import('@/entities/recurring-bulk-job/recurring-bulk-job.vue');
// prettier-ignore
const RecurringBulkJobUpdate = () => import('@/entities/recurring-bulk-job/recurring-bulk-job-update.vue');
// prettier-ignore
const RecurringBulkJobDetails = () => import('@/entities/recurring-bulk-job/recurring-bulk-job-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here
const ResourceConfigurationCpu = () => import('@/entities/resource-configuration/resource-configration-cpu.vue');
const DeviceHostGraph = () => import('@/entities/device/device-tr069/device-host-graph.vue');
const DeviceHostRoute = () => import('@/entities/device/device-tr069/device-host-route.vue');
const DeviceNeighboringWifiDiagnostic = () => import('@/entities/device/device-tr069/device-neighboring-wifi-diagnostics.vue');
const NestedRouteOrganization = () => import('@/entities/organization/nested-route.vue');
// const TR069Configuration = () => import('@/entities/tr-069-configuration/tr-069-configuration.vue');
// prettier-ignore
const TR069ConfigurationUpdate = () => import('@/entities/tr-069-configuration/tr-069-configuration-update.vue');
// prettier-ignore
const TR069ConfigurationDetails = () => import('@/entities/tr-069-configuration/tr-069-configuration-details.vue');
const ResourceConfigurationMemory = () => import('@/entities/resource-configuration/resource-configration-memory.vue');
const EmailConfigurationDetails = () => import('@/entities/email-configuration/email-configuration-details.vue');
const EmailConfigurationUpdate = () => import('@/entities/email-configuration/email-configuration-update.vue');
const SSRMSConfiguration = () => import('@/entities/ssrms-configuration/ssrms-configuration.vue');
const ResourceConfigurationStorage = () => import('@/entities/resource-configuration/resource-configration-storage.vue');
const ResourceConfigurationPing = () => import('@/entities/resource-configuration/resource-configration-ping.vue');
const ResourceConfigurationOpticalPower = () => import('@/entities/resource-configuration/resource-configration-optical-power.vue');
const ResourceConfigurationAssociatedHostPowerRx = () =>
  import('@/entities/resource-configuration/resource-configuration-associated-host-power-rx.vue');
const ResourceConfigurationNetworkInterface = () => import('@/entities/resource-configuration/resource-configration-network-interface.vue');
const Tr069ServerCredentials = () => import('@/entities/tr-069-configuration/tr-069-server-credentials/tr-069-server-credentials.vue');
const DevicePortMapping = () => import('@/entities/device/device-tr069/device-port-mapping.vue');
const DeviceVoip = () => import('@/entities/device/device-tr069/device-voip.vue');
const ReportProvisioningMismatch = () => import('@/entities/device/Report/report-provisioning-mismatch.vue');
const OrganizationFeature = () => import('@/entities/organization-feature/organization-feature.vue');
const OrganizationUser = () => import('@/entities/organization/organization-details-user.vue');
const SmsConfigurationDetails = () => import('@/entities/sms-configuration/sms-configuration-details.vue');
const ResourceConfigurationPower = () => import('@/entities/resource-configuration/olt-onu/resource-configuration-ont-onu-power.vue');
const TheftDetection = () => import('@/entities/device/theft-detection/theft-detection.vue');
const OrganizationTheftDetection = () => import('@/entities/organization/organization-theft-detection.vue');
const VoipConfiguration = () => import('@/entities/tr-069-configuration/voip/voip-configuration.vue');
const Tr069Credentials = () => import('@/entities/tr-069-configuration/credentials/tr069-credentials.vue');
const ProvisioningConfiguration = () => import('@/entities/tr-069-configuration/provisioning-configuration/provisioning-configuration.vue');
const ActiveDeviceReport = () => import('@/entities/device/Report/active-device-report.vue');
const PowerFluctuationReport = () => import('@/entities/device/Report/power-fluctuation-report.vue');
const DeviceNSLookUpDiagnostic = () => import('@/entities/device/device-tr069/device-nslookup-diagnostic.vue');
const Tr069Parameters = () => import('@/entities/device/device-tr069/tr069-parameters-view.vue');
export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'device-brand',
      name: 'DeviceBrand',
      component: DeviceBrand,
      meta: {},
    },
    {
      path: 'device-brand/new',
      name: 'DeviceBrandCreate',
      component: DeviceBrandUpdate,
      meta: {},
    },
    {
      path: 'device-brand/:id/edit',
      name: 'DeviceBrandEdit',
      component: DeviceBrandUpdate,
      meta: {},
    },
    {
      path: 'device-brand/:id/view',
      name: 'DeviceBrandView',
      component: DeviceBrandDetails,
      meta: {},
    },
    {
      path: 'device-model',
      name: 'DeviceModel',
      component: DeviceModel,
      meta: {
        pageTitle: 'Device Models',
        resource: 'Device Model',
        action: 'view',
      },
    },
    // {
    //   path: 'device-model/new',
    //   name: 'DeviceModelCreate',
    //   component: DeviceModelUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'device-model/:deviceModelId/edit',
    //   name: 'DeviceModelEdit',
    //   component: DeviceModelUpdate,
    //   meta: { },
    // },
    {
      path: 'device-model/:id/view',
      name: 'DeviceModelView',
      component: DeviceModelDetails,
      meta: {
        pageTitle: 'Device Model',
        resource: 'Device Model',
        action: 'view',
      },
    },
    // {
    //   path: 'device-firmware',
    //   name: 'DeviceFirmware',
    //   component: DeviceFirmware,
    //   meta: { },
    // },
    // {
    //   path: 'device-firmware/new',
    //   name: 'DeviceFirmwareCreate',
    //   component: DeviceFirmwareUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'device-firmware/:deviceFirmwareId/edit',
    //   name: 'DeviceFirmwareEdit',
    //   component: DeviceFirmwareUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'device-firmware/:deviceFirmwareId/view',
    //   name: 'DeviceFirmwareView',
    //   component: DeviceFirmwareDetails,
    //   meta: { },
    // },
    // {
    //   path: 'firmware-content',
    //   name: 'FirmwareContent',
    //   component: FirmwareContent,
    //   meta: { },
    // },
    // {
    //   path: 'firmware-content/new',
    //   name: 'FirmwareContentCreate',
    //   component: FirmwareContentUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'firmware-content/:firmwareContentId/edit',
    //   name: 'FirmwareContentEdit',
    //   component: FirmwareContentUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'firmware-content/:firmwareContentId/view',
    //   name: 'FirmwareContentView',
    //   component: FirmwareContentDetails,
    //   meta: { },
    // },
    // {
    //   path: 'device-image',
    //   name: 'DeviceImage',
    //   component: DeviceImage,
    //   meta: { },
    // },
    // {
    //   path: 'device-image/new',
    //   name: 'DeviceImageCreate',
    //   component: DeviceImageUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'device-image/:deviceImageId/edit',
    //   name: 'DeviceImageEdit',
    //   component: DeviceImageUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'device-image/:deviceImageId/view',
    //   name: 'DeviceImageView',
    //   component: DeviceImageDetails,
    //   meta: { },
    // },
    // {
    //   path: 'device-tags',
    //   name: 'DeviceTags',
    //   component: DeviceTags,
    //   meta: { },
    // },
    // {
    //   path: 'device-tags/new',
    //   name: 'DeviceTagsCreate',
    //   component: DeviceTagsUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'device-tags/:deviceTagsId/edit',
    //   name: 'DeviceTagsEdit',
    //   component: DeviceTagsUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'device-tags/:deviceTagsId/view',
    //   name: 'DeviceTagsView',
    //   component: DeviceTagsDetails,
    //   meta: { },
    // },
    {
      path: 'device/:page?',
      name: 'Device',
      component: DeviceGroup,
      props: true,
      meta: {
        resource: 'Device',
        action: 'view',
        pageTitle: 'Devices',
      },
    },
    // {
    //   path: 'device/new',
    //   name: 'DeviceCreate',
    //   component: DeviceUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'device/:deviceId/edit',
    //   name: 'DeviceEdit',
    //   component: DeviceUpdate,
    //   meta: { },
    // },
    {
      path: 'device/:id/detail',
      name: 'DeviceView',
      component: DeviceDetails,
      redirect: 'device/:id/detail/overview',
      children: [
        {
          path: 'overview',
          name: 'DeviceDashboardView',
          component: DeviceDashboardView,
          meta: {
            resource: 'Device',
            action: 'view',
          },
        },
        {
          path: 'monitoring',
          name: 'DeviceMonitoring',
          component: NestedRoute,
          children: [
            {
              path: 'network-interface',
              name: 'DeviceNetworkInterfaceView',
              component: DeviceNetworkInterface,
              meta: {
                resource: 'Device',
                action: 'view',
              },
            },
            {
              path: 'port-power-interface',
              name: 'DeviceOpticalPortPowerView',
              component: DeviceOpticalPortPowerInterface,
              meta: {
                resource: 'Device',
                action: 'view',
              },
            },
          ],
        },
        {
          path: 'tr-069',
          name: 'DeviceConfiguration',
          component: NestedRoute,
          children: [
            {
              path: 'wi-fi',
              name: 'DeviceDetailWlan',
              component: DeviceDetailWlan,
              meta: {
                resource: 'Device',
                action: 'view_wifi',
              },
            },
            {
              path: 'lan',
              name: 'DeviceDetailLan',
              component: DeviceDetailLan,
              meta: {
                resource: 'Device',
                action: 'view_lan',
              },
            },
            {
              path: 'wan',
              name: 'DeviceDetailWan',
              component: DeviceDetailWan,
              meta: {
                resource: 'Device',
                action: 'view_wan',
              },
            },
            {
              path: 'voip',
              name: 'DeviceVoip',
              component: DeviceVoip,
              meta: {
                resource: 'Device',
                action: 'view_voip',
              },
            },
            {
              path: 'dhcp',
              name: 'DeviceDetailDHCP',
              component: DeviceDetailDHCP,
              meta: {
                resource: 'Device',
                action: 'view_dhcp',
              },
            },
            {
              path: 'ping',
              name: 'DevicePingDiagnostic',
              component: DevicePingDiagnostic,
              meta: {
                resource: 'Device',
                action: 'ping_diagnostics',
              },
            },
            {
              path:'nslook-up',
              name: 'DeviceNSLookUpDiagnostic',
              component: DeviceNSLookUpDiagnostic,
              meta: {
                resource: 'Device',
                action: 'ns_lookup_diagnostics',
              },
            },
            {
              path: 'trace-route',
              name: 'DeviceTraceDiagnostic',
              component: DeviceTraceDiagnostic,
              meta: {
                resource: 'Device',
                action: 'trace_route_diagnostics',
              },
            },
            {
              path: 'speed-test',
              name: 'DeviceSpeedTest',
              component: DeviceSpeedTest,
              meta: {
                resource: 'Device',
                action: 'speed_test_diagnostics',
              },
            },
            {
              path: 'neighboring-wifi',
              name: 'DeviceNeighboringWifiDiagnostic',
              component: DeviceNeighboringWifiDiagnostic,
              meta: {
                resource: 'Device',
                action: 'neighboring_wifi_diagnostics',
              },
            },
            {
              path: 'host',
              name: 'DeviceHostRoute',
              component: DeviceHostRoute,
              children: [
                {
                  path: 'host-device',
                  name: 'DeviceDetailHost',
                  component: DeviceDetailHost,
                  meta: {
                    resource: 'Device',
                    action: 'associated_host',
                    pageTitle: 'Associated Hosts',
                  },
                },
                {
                  path: 'host-graph',
                  name: 'DeviceHostGraph',
                  component: DeviceHostGraph,
                  meta: {
                    resource: 'Device',
                    action: 'view',
                    pageTitle: 'Associated Host Graph',
                  },
                },
              ],
            },
            {
              path: 'port-mapping',
              name: 'DevicePortMapping',
              component: DevicePortMapping,
              meta: {
                resource: 'Device',
                action: 'port_mapping',
              },
            },
            {
              path: 'tr-069-parameter',
              name: 'Tr069Parameters',
              component: Tr069Parameters,
              meta: {
                resource: 'Device',
                action: 'tr069_parameters',
              },
            },
            {
              path: 'actions',
              name: 'Actions',
              component: DeviceAction,
              meta: {
                resource: 'Device',
                action: 'view',
              },
            },
          ],
        },
        {
          path: 'alert',
          name: 'Alert',
          component: NestedRoute,
          children: [
            {
              path: 'device-alert',
              name: 'DeviceAlertView',
              component: H8AlertTable,
              meta: {
                resource: 'Alert',
                action: 'view',
              },
            },
            {
              path: 'device-interface-threshold-alert',
              name: 'InterfaceAlertConfView',
              component: InterfaceAlertConfView,
              meta: {
                resource: 'Device',
                action: 'interface_alert_threshold',
              },
            },
            {
              path: 'device-optical-power-port-threshold',
              name: 'OpticalPowerPortThresholdView',
              component: OpticalPowerPortThresholdView,
              meta: {
                resource: 'Device',
                action: 'optical_power_port_alert_threshold',
              },
            },
          ],
        },
        {
          path: 'administration',
          name: 'DeviceAdministration',
          component: NestedRoute,
          children: [
            {
              path: 'snmp',
              name: 'DeviceConfSnmpView',
              component: DevideConfSnmpDetails,
              meta: {
                resource: 'Device',
                action: 'create_update_snmp_configuration',
              },
            },
            {
              path: 'monitor',
              name: 'DeviceMonitorsView',
              component: Monitor,
              meta: {
                resource: 'Monitor',
                action: 'view',
              },
            },
            {
              path: 'device-notification',
              name: 'DeviceNotificationView',
              component: DeviceNotification,
              meta: {
                resource: 'Device',
                action: 'create_update_notification_configuration',
              },
            },
            {
              path: 'notification-recipients',
              name: 'DeviceNotificationRecipients',
              component: DeviceNotificationRecipients,
              meta: {
                resource: 'Device Notification Recipients',
                action: 'view',
              },
            },
            {
              path: 'job',
              name: 'Job',
              component: Job,
              meta: {
                resource: 'Device',
                action: 'view_job',
              },
            },
          ],
        },
      ],
      meta: {
        resource: 'Device',
        action: 'view',
        pageTitle: 'Device',
      },
    },
    // {
    //   path: 'device-conf-tr-069',
    //   name: 'DeviceConfTR069',
    //   component: DeviceConfTR069,
    //   meta: { },
    // },
    // {
    //   path: 'device-conf-tr-069/new',
    //   name: 'DeviceConfTR069Create',
    //   component: DeviceConfTR069Update,
    //   meta: { },
    // },
    // {
    //   path: 'device-conf-tr-069/:deviceConfTR069Id/edit',
    //   name: 'DeviceConfTR069Edit',
    //   component: DeviceConfTR069Update,
    //   meta: { },
    // },
    // {
    //   path: 'device-conf-tr-069/:deviceConfTR069Id/view',
    //   name: 'DeviceConfTR069View',
    //   component: DeviceConfTR069Details,
    //   meta: { },
    // },
    // {
    //   path: 'devide-conf-snmp',
    //   name: 'DevideConfSnmp',
    //   component: DevideConfSnmp,
    //   meta: { },
    // },
    // {
    //   path: 'devide-conf-snmp/new',
    //   name: 'DevideConfSnmpCreate',
    //   component: DevideConfSnmpUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'devide-conf-snmp/:devideConfSnmpId/edit',
    //   name: 'DevideConfSnmpEdit',
    //   component: DevideConfSnmpUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'device/:id/snmp',
    //   name: 'DeviceConfSnmpView',
    //   component: DeviceNavigationMenu,
    //   meta: {
    //     resource: 'Device',
    //     action: 'view',
    //     pageTitle: 'Device',
    //   },
    // },
    // {
    //   path: 'monitor',
    //   name: 'Monitor',
    //   component: Monitor,
    //   meta: { },
    // },
    // {
    //   path: 'monitor/new',
    //   name: 'MonitorCreate',
    //   component: MonitorUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'monitor/:monitorId/edit',
    //   name: 'MonitorEdit',
    //   component: MonitorUpdate,
    //   meta: { },
    // },
    {
      path: 'monitor/:id/view',
      name: 'MonitorView',
      component: MonitorDetails,
      meta: {
        pageTitle: 'Monitor',
        resource: 'Monitor',
        action: 'view',
      },
    },
    {
      path: 'h-8-alert',
      name: 'H8Alert',
      component: H8Alert,
      meta: {
        pageTitle: 'Alerts',
        resource: 'Alert',
        action: 'view',
      },
    },
    // {
    //   path: 'h-8-alert/new',
    //   name: 'H8AlertCreate',
    //   component: H8AlertUpdate,
    //   meta: {  pageTitle: 'Alerts' },
    // },
    // {
    //   path: 'h-8-alert/:h8AlertId/edit',
    //   name: 'H8AlertEdit',
    //   component: H8AlertUpdate,
    //   meta: {  pageTitle: 'Alerts' },
    // },
    {
      path: 'h-8-alert/:id/view',
      name: 'H8AlertView',
      component: H8AlertDetails,
      meta: {
        pageTitle: 'Alerts',
        resource: 'Alert',
        action: 'view',
      },
    },
    {
      path: 'device/:id/alerts',
      name: 'DeviceH8AlertConfView',
      component: H8AlertTable,
      meta: {
        pageTitle: 'Device',
        resource: 'Device',
        action: 'view',
      },
    },
    // {
    //   path: 'device',
    //   name: 'Device',
    //   component: DeviceGroup,
    //   meta: {
    //     pageTitle: 'Device',
    //     resource: 'Device',
    //     action: 'view',
    //   },
    // },
    // {
    //   path: 'device/new',
    //   name: 'DeviceCreate',
    //   component: DeviceUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'device/:deviceId/edit',
    //   name: 'DeviceEdit',
    //   component: DeviceUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'device/:deviceId/view',
    //   name: 'DeviceView',
    //   component: DeviceDetails,
    //   meta: { },
    // },
    {
      path: 'email-recipient-list',
      name: 'EmailRecipientList',
      component: EmailRecipientList,
      meta: {
        pageTitle: 'Email Recipient List',
        resource: 'Email Recipient List',
        action: 'view',
      },
    },
    // {
    //   path: 'email-recipient-list/new',
    //   name: 'EmailRecipientListCreate',
    //   component: EmailRecipientListUpdate,
    //   meta: {  pageTitle: 'Email Recipient List' },
    // },
    // {
    //   path: 'email-recipient-list/:emailRecipientListId/edit',
    //   name: 'EmailRecipientListEdit',
    //   component: EmailRecipientListUpdate,
    //   meta: {  pageTitle: 'Email Recipient List' },
    // },
    {
      path: 'email-recipient-list/:id/view',
      name: 'EmailRecipientListView',
      component: EmailRecipientListDetails,
      meta: {
        pageTitle: 'Email Recipient List',
        resource: 'Email Recipient List',
        action: 'view',
      },
    },
    {
      path: 'sms-recipient-list',
      name: 'SMSRecipientList',
      component: SMSRecipientList,
      meta: {
        pageTitle: 'SMS Recipient List',
        resource: 'SMS Recipient List',
        action: 'view',
      },
    },
    // {
    //   path: 'sms-recipient-list/new',
    //   name: 'SMSRecipientListCreate',
    //   component: SMSRecipientListUpdate,
    //   meta: {  pageTitle: 'SMS Recipient List' },
    // },
    // {
    //   path: 'sms-recipient-list/:sMSRecipientListId/edit',
    //   name: 'SMSRecipientListEdit',
    //   component: SMSRecipientListUpdate,
    //   meta: {  pageTitle: 'SMS Recipient List' },
    // },
    {
      path: 'sms-recipient-list/:id/view',
      name: 'SMSRecipientListView',
      component: SMSRecipientListDetails,
      meta: {
        pageTitle: 'SMS Recipient List',
        resource: 'SMS Recipient List',
        action: 'view',
      },
    },
    // {
    //   path: 'device-notification-recipient',
    //   name: 'DeviceNotificationRecipient',
    //   component: DeviceNotificationRecipient,
    //   meta: { },
    // },
    // {
    //   path: 'device-notification-recipient/new',
    //   name: 'DeviceNotificationRecipientCreate',
    //   component: DeviceNotificationRecipientUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'device-notification-recipient/:deviceNotificationRecipientId/edit',
    //   name: 'DeviceNotificationRecipientEdit',
    //   component: DeviceNotificationRecipientUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'device-notification-recipient/:deviceNotificationRecipientId/view',
    //   name: 'DeviceNotificationRecipientView',
    //   component: DeviceNotificationRecipientDetails,
    //   meta: { },
    // },
    {
      path: 'role',
      name: 'Role',
      component: Role,
      meta: {
        resource: 'Role',
        action: 'view',
        pageTitle: 'Role',
      },
    },
    // {
    //   path: 'role/new',
    //   name: 'RoleCreate',
    //   component: RoleUpdate,
    //   meta: {
    //     resource: 'Role',
    //     action: 'create',
    //   },
    // },
    // {
    //   path: 'role/:roleId/edit',
    //   name: 'RoleEdit',
    //   component: RoleUpdate,
    //   meta: { },
    // },
    {
      path: 'role/:id/view',
      name: 'RoleView',
      component: RoleDetails,
      meta: {
        resource: 'Role',
        action: 'view',
        pageTitle: 'Role',
      },
    },
    // {
    //   path: 'job',
    //   name: 'Job',
    //   component: Job,
    //   meta: { pageTitle: 'Job' },
    // },
    // {
    //   path: 'job/new',
    //   name: 'JobCreate',
    //   component: JobUpdate,
    //   meta: { pageTitle: 'Job' },
    // },
    // {
    //   path: 'job/:jobId/edit',
    //   name: 'JobEdit',
    //   component: JobUpdate,
    //   meta: { pageTitle: 'Job' },
    // },
    {
      path: 'job/:id/view',
      name: 'JobView',
      component: JobDetails,
      meta: { pageTitle: 'Job' },
    },
    // {
    //   path: 'ability',
    //   name: 'Ability',
    //   component: Ability,
    //   meta: { },
    // },
    // {
    //   path: 'ability/new',
    //   name: 'AbilityCreate',
    //   component: AbilityUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'ability/:abilityId/edit',
    //   name: 'AbilityEdit',
    //   component: AbilityUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'ability/:abilityId/view',
    //   name: 'AbilityView',
    //   component: AbilityDetails,
    //   meta: { },
    // },
    // {
    //   path: 'device-group',
    //   name: 'DeviceGroup',
    //   component: DeviceGroup,
    //   meta: {  },
    // },
    // {
    //   path: 'device-group/new',
    //   name: 'DeviceGroupCreate',
    //   component: DeviceGroupUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'device-group/:id/edit',
    //   name: 'DeviceGroupEdit',
    //   component: DeviceGroupUpdate,
    //   meta: { },
    // },
    // {
    //   path: 'device-group/:id/view',
    //   name: 'DeviceGroupView',
    //   component: DeviceGroupDetails,
    //   meta: {},
    // },
    {
      path: 'alert-profile',
      name: 'AlertProfile',
      component: AlertProfile,
      meta: { resource: 'Alert Profile', action: 'view', pageTitle: 'Alert Profiles' },
    },
    // {
    //   path: 'alert-profile/new',
    //   name: 'AlertProfileCreate',
    //   component: AlertProfileUpdate,
    //   meta: { authorities: [Authority.USER] },
    // },
    // {
    //   path: 'alert-profile/:alertProfileId/edit',
    //   name: 'AlertProfileEdit',
    //   component: AlertProfileUpdate,
    //   meta: { authorities: [Authority.USER] },
    // },
    {
      path: 'alert-profile/:id/view',
      name: 'AlertProfileView',
      component: AlertProfileDetails,
      meta: { resource: 'Alert Profile', action: 'view', pageTitle: 'Alert Profile' },
    },
    {
      path: 'device-filter',
      name: 'DeviceFilter',
      component: DeviceFilter,
      meta: { resource: 'Device Filter', action: 'view' },
    },
    // {
    //   path: 'device-filter/new',
    //   name: 'DeviceFilterCreate',
    //   component: DeviceFilterUpdate,
    //   meta: { authorities: [Authority.USER] },
    // },
    // {
    //   path: 'device-filter/:id/edit',
    //   name: 'DeviceFilterEdit',
    //   component: DeviceFilterUpdate,
    //   meta: { authorities: [Authority.USER] },
    // },
    {
      path: 'device-filter/:id/view',
      name: 'DeviceFilterView',
      component: DeviceFilterDetails,
      meta: { resource: 'Device Filter', action: 'view', pageTitle: 'Device Filter' },
    },
    {
      path: 'job',
      name: 'Job',
      component: Job,
      meta: { resource: 'Job', action: 'view' },
    },
    {
      path: 'bulk-job',
      name: 'BulkJob',
      component: BulkJob,
      meta: { resource: 'Job', action: 'view' },
    },
    // {
    //   path: 'bulk-job/new',
    //   name: 'BulkJobCreate',
    //   component: BulkJobUpdate,
    //   meta: { authorities: [Authority.USER] },
    // },
    // {
    //   path: 'bulk-job/:id/edit',
    //   name: 'BulkJobEdit',
    //   component: BulkJobUpdate,
    //   meta: { authorities: [Authority.USER] },
    // },
    {
      path: 'bulk-job/:id/view',
      name: 'BulkJobView',
      component: BulkJobDetails,
      meta: { resource: 'Job', action: 'view' },
    },
    {
      path: 'organization',
      name: 'Organization',
      component: Organization,
      meta: { authorities: [Authority.USER], resource: 'Organization', action: 'view' },
    },
    {
      path: 'organization/new',
      name: 'OrganizationCreate',
      component: OrganizationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organization/:id/edit',
      name: 'OrganizationEdit',
      component: OrganizationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organization/:id?/view',
      name: 'OrganizationView',
      component: OrganizationDetails,
      props: true,
      // redirect: 'organization/:id/view/tr069-configuration',
      children: [
        {
          path: 'tr069-configuration',
          name: 'NestedRouteOrganization',
          component: NestedRouteOrganization,
          props: true,
          children: [
            {
              path: 'voip',
              name: 'VoipConfiguration',
              component: VoipConfiguration,
              meta: {
                resource:'Organization',
                action:'view_voip',
              },
            },
            {
              path: 'provisioning-configuration',
              name: 'ProvisioningConfiguration',
              component: ProvisioningConfiguration,
              meta: {
                resource:'Organization',
                action:'view_tr069_service',
              },
            },
            {
              path: 'tr069-credentials',
              name: 'Tr069Credentials',
              component: Tr069Credentials,
              meta: {
                resource:'Organization',
                action:'view_tr069_credentials',
              },
            },
          ],
        },
        {
          path: 'resource-configuration',
          name: 'NestedRouteOrganization',
          component: NestedRouteOrganization,
          props: true,
          children: [
            {
              path: 'cpu',
              name: 'ResourceConfigurationCpu',
              component: ResourceConfigurationCpu,
              props: true,
              meta: {
                resource:'Organization',
                action:'view_resource_cpu',
              },
            },
            {
              path: 'memory',
              name: 'ResourceConfigurationMemory',
              component: ResourceConfigurationMemory,
              meta: {
                resource:'Organization',
                action:'view_resource_memory',
              },
            },
            {
              path: 'storage',
              name: 'ResourceConfigurationStorage',
              component: ResourceConfigurationStorage,
              meta: {
                resource:'Organization',
                action:'view_resource_storage',
              },
            },
            {
              path: 'ping',
              name: 'ResourceConfigurationPing',
              component: ResourceConfigurationPing,
              meta: {
                resource:'Organization',
                action:'view_resource_ping',
              },
            },
            {
              path: 'network-interface',
              name: 'ResourceConfigurationNetworkInterface',
              component: ResourceConfigurationNetworkInterface,
              meta: {
                resource:'Organization',
                action:'view_resource_network_interface',
              },
            },
            {
              path: 'optiocal-power',
              name: 'ResourceConfigurationOpticalPower',
              component: ResourceConfigurationOpticalPower,
              meta: {
                resource:'Organization',
                action:'view_resource_optical_power',
              },
            },
            {
              path: 'ont-onu-power',
              name: 'ResourceConfigurationPower',
              component: ResourceConfigurationPower,
              meta: {
                resource:'Organization',
                action:'view_resource_ont_onu_power',
              },
            },
            {
              path: 'associated-host-rx',
              name: 'ResourceConfigurationAssociatedHostPowerRx',
              component: ResourceConfigurationAssociatedHostPowerRx,
              meta: {
                resource:'Organization',
                action:'view_resource_host_signal_strength',
              },
            },
          ],
        },
        {
          path: 'email-Configuration',
          name: 'EmailConfiguration',
          component: EmailConfigurationDetails,
          props: true,
          meta: {
            resource:'Organization',
            action:'view_email_configuration',
          },
        },
        {
          path: 'sms-Configuration',
          name: 'SmsConfiguration',
          component: SmsConfigurationDetails,
          props: true,
          meta: {
            resource:'Organization',
            action:'view_sms_configuration',
          },
        },
        {
          path: 'ssrms-Configuration',
          name: 'SSRMSConfiguration',
          component: SSRMSConfiguration,
          props: true,
          meta: {
            resource:'Organization',
            action:'view_ssrms_configuration',
          },
        },
        {
          path: 'organization-feature',
          name: 'OrganizationFeature',
          component: OrganizationFeature,
          props: true,
          meta: {
            resource:'Organization',
            action:'view_features',
          },
        },
        {
          path: 'organization-user',
          name: 'OrganizationUser',
          component: OrganizationUser,
          props: true,
          meta: {
            resource:'Organization',
            action:'view_users',
          },
        },
        {
          path: 'organization-theft-detection',
          name: 'OrganizationTheftDetection',
          component: OrganizationTheftDetection,
          props: true,
          meta: {
            resource:'Organization',
            action:'view_theft_detection',
          },
        },
      ],
      // meta: { authorities: [Authority.USER] },
    },
    {
      path: 'resource-configuration',
      name: 'ResourceConfiguration',
      component: ResourceConfiguration,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'resource-configuration/new',
      name: 'ResourceConfigurationCreate',
      component: ResourceConfigurationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'resource-configuration/:id/edit',
      name: 'ResourceConfigurationEdit',
      component: ResourceConfigurationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'resource-configuration/:id/view',
      name: 'ResourceConfigurationView',
      component: ResourceConfigurationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'recurring-bulk-job',
      name: 'RecurringBulkJob',
      component: RecurringBulkJob,
      meta: { resource: 'Recurring Bulk Job', action: 'view' },
    },
    {
      path: 'recurring-bulk-job/:id/view',
      name: 'RecurringBulkJobView',
      component: RecurringBulkJobDetails,
      meta: { resource: 'Recurring Bulk Job', action: 'view' },
    },
    {
      path: 'report/provisioning-mismatch',
      name: 'ReportProvisioningMismatch',
      component: ReportProvisioningMismatch,
      meta: { resource: 'Report', action: 'provisioning_mismatch' },
    },
    {
      path: 'report/theft-detection',
      name: 'TheftDetection',
      component: TheftDetection,
      meta: { resource: 'Organization', action: 'view' },
    },
    {
      path: 'report/active-device-report',
      name: 'ActiveDeviceReport',
      component: ActiveDeviceReport,
      meta: { resource: 'Report', action: 'active_device_report' },
    },
    {
      path: 'report/power-fluctuation-report',
      name: 'PowerFluctuationReport',
      component: PowerFluctuationReport,
      meta: { resource: 'Report', action: 'power_fluctuation_report' },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
    // {
    //   path: 'tr069-configuration',
    //   name: 'TR069Configuration',
    //   component: TR069Configuration,
    //   meta: { authorities: [Authority.USER] },
    // },
    {
      path: 'tr069-configuration/new',
      name: 'TR069ConfigurationCreate',
      component: TR069ConfigurationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tr069-configuration/:id/edit',
      name: 'TR069ConfigurationEdit',
      component: TR069ConfigurationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tr069-configuration/:id/view',
      name: 'TR069ConfigurationView',
      component: TR069ConfigurationDetails,
      meta: { authorities: [Authority.USER] },
    },

    {
      path: 'email-Configuration/new',
      name: 'EmailConfigurationCreate',
      component: EmailConfigurationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'email-Configuration/:id/edit',
      name: 'EmailConfigurationEdit',
      component: EmailConfigurationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'email-Configuration/:id/view',
      name: 'EmailConfigurationView',
      component: EmailConfigurationDetails,
      meta: { authorities: [Authority.USER] },
    },
  ],
};
