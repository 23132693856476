export default [
  {
    title: 'Device',
    icon: 'mdi-devices',
    children: [
      {
        title: 'Device Model',
        icon: 'mdi-server',
        route: '/device-model',
        action: 'view',
        resource: 'Device Model',
        feature: '',
      },
      {
        title: 'Devices',
        icon: 'mdi-router-network',
        route: '/device',
        action: 'view',
        resource: 'Device',
        feature: '',
      },
      {
        title: 'Device Filter',
        icon: 'mdi-filter',
        route: '/device-filter',
        action: 'view',
        resource: 'Device Filter',
        feature: 'BULK_JOBS',
      },
      {
        title: 'Job',
        icon: 'mdi-clipboard',
        route: '/job',
        action: 'view',
        resource: 'Job',
      },
      {
        title: 'Bulk Job',
        icon: 'mdi-clipboard-list',
        route: '/bulk-job',
        action: 'view',
        resource: 'Job',
        feature: 'BULK_JOBS',
      },
      {
        title: 'Recurring Bulk Job',
        icon: 'mdi-clipboard-clock',
        route: '/recurring-bulk-job',
        action: 'view',
        resource: 'Recurring Bulk Job',
        feature: 'RECURRING_BULK_JOBS',
      },
      // {
      //   title: 'Devices',
      //   icon: 'mdi-router-network',
      //   route: 'device',
      //   action: 'view',
      //   resource: 'Device',
      // }
    ],
  },
  {
    title: 'Notification',
    icon: 'mdi-bell-cog',
    action: 'view',
    children: [
      {
        title: 'Alerts',
        icon: 'mdi-bell-badge',
        route: '/h-8-alert',
        action: 'view',
        resource: 'Alert',
        feature: '',
      },
      {
        title: 'Alert Profile',
        icon: 'mdi-bell-plus',
        route: '/alert-profile',
        action: 'view',
        resource: 'Alert Profile',
        feature: '',
      },
      {
        title: 'Email Recipients',
        icon: 'mdi-at',
        route: '/email-recipient-list',
        action: 'view',
        resource: 'Email Recipient List',
        feature: 'EMAIL_NOTIFICATION',
      },
      {
        title: 'SMS Recipients',
        icon: 'mdi-message-alert',
        route: '/sms-recipient-list',
        action: 'view',
        resource: 'SMS Recipient List',
        feature: 'SMS_NOTIFICATION',
      },
    ],
  },
  {
    title: 'Reports',
    icon: 'mdi-book-open-variant',
    action: 'view',
    children: [
      {
        title: 'Provisioning Mismatch',
        icon: '',
        route: '/report/provisioning-mismatch',
        action: 'provisioning_mismatch',
        resource: 'Report',
      },
      {
        title: 'Active Device',
        icon: '',
        route: '/report/active-device-report',
        action: 'active_device_report',
        resource: 'Report',
      },
      {
        title: 'Theft Detected',
        icon: '',
        route: '/report/theft-detection',
        action: 'theft_detected',
        resource: 'Report',
      },
      {
        title: 'Power Fluctuation Report',
        icon: '',
        route: '/report/power-fluctuation-report',
        action: 'power_fluctuation_report',
        resource: 'Report',
      },
    ],
  },
  {
    title: 'Job',
    icon: 'clipboard-check',
    route: 'Job',
    action: 'read',
    resource: 'Job',
  },
];
