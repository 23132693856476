var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-iterator", {
    attrs: {
      items: _vm.items,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "disable-pagination": _vm.itemsPerPage ? false : true,
      "items-per-page": _vm.itemsPerPage,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (props) {
            return [
              _c(
                "v-row",
                _vm._l(props.items, function (item, itemIndex) {
                  return _c(
                    "v-col",
                    {
                      key: itemIndex,
                      attrs: {
                        lg: _vm.lg,
                        md: _vm.md,
                        sm: _vm.sm,
                        cols: _vm.cols,
                      },
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "ma-1", attrs: { elevation: "3" } },
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "text-h6 font-weight-bold" },
                                    [
                                      _vm._t("item.header", null, {
                                        item: item,
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._t("item.actionButton", null, { item: item }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-list",
                                { attrs: { dense: "" } },
                                _vm._l(
                                  _vm.headers,
                                  function (header, headerIndex) {
                                    return _c(
                                      "v-list-item",
                                      { key: headerIndex },
                                      [
                                        header.text
                                          ? _c(
                                              "v-list-item-content",
                                              {
                                                staticClass: "text-subtitle-2",
                                              },
                                              [_vm._v(_vm._s(header.text))]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "v-list-item-content",
                                          {
                                            staticClass:
                                              "text-body-2 align-end",
                                            staticStyle: {
                                              display: "contents",
                                            },
                                          },
                                          [
                                            header.layout == "default" ||
                                            header.layout == null
                                              ? _vm._t(
                                                  ["item." + header.value],
                                                  function () {
                                                    return [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.getValue(
                                                              item,
                                                              header.value
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  },
                                                  { item: item }
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        header.layout == "custom"
                                          ? _vm._t(
                                              ["item." + header.value],
                                              null,
                                              { item: item }
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }